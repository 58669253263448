import { useSharedSelector } from "../store";

/**
 * If the user's multiuser account (via license) has this setting enabled,
 * then they can't rate phrases easy if their recommended rating is not easy
 */
export function useShouldDisableEasyRatingsIfNotRecommended() {
  // We may (in the future) allow admins to still rate things easy
  // even though they are part of a multiuser account
  return useSharedSelector((s) => Boolean(s.user.multiuser_account?.disable_easy_ratings_if_not_recommended));
}
