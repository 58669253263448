import { RoundedButton } from "@rocketlanguages/ui";
import DrawItContextProvider, { DrawItContext } from "./includes/context";
import ScriptWriter, { type ScriptWriterProps } from "./includes/HanziWriter/ScriptWriter";
import { useContext, useEffect, useRef, useState } from "react";
import useDrawIt, { useDrawItPosition } from "../../../hooks/useDrawIt";
import DrawItComplete from "./includes/DrawItCompleteFacelift";
import Instructions from "./includes/Instructions";
import LessonContext from "../../../context/LessonContext";
import { RateableTestTypeIds, RateableTestTypes } from "../../../utils/constants";
import RateableTestUI from "../RateableTestUI";
import { ReinforcementContext } from "../../../context/ReinforcementContext";
import Settings from "./includes/Settings";
import { getNextRateableTest } from "../../../utils";
import { useRateableTestRatingLevel } from "../../../hooks/useLessonRateableTests";
import useTranslation from "../../../hooks/useTranslation";
import { useSharedSelector, useSharedStore } from "../../../store";
import { MissileRatingButtons } from "../RateableTestUI/buttons/MissileRatingButtons";
import { getRecommendedRatingButton } from "../RateableTestUI/buttons/getRecommendedRatingButton";
import usePhraseHotkeys from "../../../hooks/usePhraseHotkeys";
import { shallow } from "zustand/shallow";

export function DrawItFacelift({ rateableTestId }: { rateableTestId: number }) {
  const t = useTranslation();
  const ratingLevel = useRateableTestRatingLevel(rateableTestId);

  const lessonId = useContext(LessonContext).id;
  const drawItStore = useDrawIt({ rateableTestId, lessonId, mode: "unrated_components" });
  const position = useDrawItPosition(drawItStore);

  const numComponents = drawItStore((state) => state.allComponents.length);

  /** Used for phrase tests to maintain focus/keybinds after progressing */
  const testContainerRef = useRef<HTMLDivElement>(null);

  return (
    <DrawItContextProvider testContainerRef={testContainerRef}>
      <RateableTestUI.Container
        ref={testContainerRef}
        rateableTestTypeId={RateableTestTypeIds.DRAW_IT}
        instructions={<Instructions />}
        settings={<Settings />}
      >
        <RateableTestUI.Header
          testName={t("draw-it")}
          testSubheading={t("draw-it-subheading")}
          rateableTestId={rateableTestId}
          rateableTestTypeId={RateableTestTypeIds.DRAW_IT}
          ratingLevel={ratingLevel}
          position={position}
          total={numComponents}
        />
        <RateableTestUI.Body>
          <DrawItTest rateableTestId={rateableTestId} store={drawItStore} />
        </RateableTestUI.Body>
      </RateableTestUI.Container>
    </DrawItContextProvider>
  );
}

export function DrawItTest({ rateableTestId, store }: { rateableTestId: number; store: ReturnType<typeof useDrawIt> }) {
  const t = useTranslation();
  const context = useContext(DrawItContext);
  if (!context) {
    throw new Error("DrawItContext not found. Please wrap in DrawItContextProvider");
  }
  const { setActivePhraseTest } = useContext(ReinforcementContext);
  const [state, setState] = useState<"idle" | "started" | "revealed">("idle");
  const lessonId = useContext(LessonContext).id;
  const reduxStore = useSharedStore();

  const componentRatings =
    useSharedSelector((store) => store.lesson.entities.user_rateable_test_component_ratings[rateableTestId]) || [];
  const numHardComponents = componentRatings.filter((item) => item.value <= 20).length;
  const numGoodComponents = componentRatings.filter((item) => item.value > 20 && item.value <= 80).length;

  const { status, actions, currentComponent } = store(
    (state) => ({
      actions: state.actions,
      status: state.status,
      currentComponent: state.testComponents[state.index],
    }),
    shallow,
  );

  usePhraseHotkeys({
    phraseTestRef: context.testContainerRef,
    // don't allow revealing if idle, only in the "started" state
    revealed: state === "revealed" || state === "idle",
    onReveal: () => setState("revealed"),
    onRate: actions.rate,
  });

  useEffect(() => {
    setState("idle");
  }, [currentComponent]);

  useEffect(() => {
    context?.setQuizState(state);
  }, [state, context]);

  if (status === "complete") {
    const nextRateableTest = getNextRateableTest({ rateableTestId, lessonId, store: reduxStore });
    const localeKey = nextRateableTest ? RateableTestTypes[nextRateableTest.rateable_test_type_id]?.code : null;
    const nextTestName = localeKey ? t(localeKey) : null;

    return (
      <DrawItComplete
        nextActivityTitle={nextTestName ? `Next Activity: ${nextTestName}` : undefined}
        nextActivityHref={localeKey ? `#${localeKey}` : undefined}
        completedWithinSession={status === "complete"}
        onReset={() => {
          actions.reset();
        }}
        easyCount={componentRatings.length - (numHardComponents + numGoodComponents)}
        goodCount={numGoodComponents}
        hardCount={numHardComponents}
        numQuestions={componentRatings.length}
        redoNonEasyCharacters={() => {
          actions.redoNonEasyCharacters();
        }}
      />
    );
  }

  if (status === "idle") {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <RoundedButton
          className="hover:bg-missileaccent/90 w-full max-w-60 bg-missileaccent font-semibold text-white"
          onClick={() => {
            setActivePhraseTest(rateableTestId);
            actions.start();
          }}
        >
          {t("get-started")}
        </RoundedButton>
      </div>
    );
  }

  function renderControlButtons({ quiz, startQuiz, stopQuiz, rating }: Parameters<ScriptWriterProps["controls"]>[0]) {
    if (state === "revealed") {
      return (
        <div className="flex h-20 w-full flex-col items-center justify-end">
          <MissileRatingButtons
            disableButtonsIfNotRecommended={false}
            text="How hard did you find this phrase?"
            recommended={getRecommendedRatingButton(rating)}
            onRate={actions.rate}
            numEasy={componentRatings.length - (numHardComponents + numGoodComponents)}
            numGood={numGoodComponents}
            numHard={numHardComponents}
          />
        </div>
      );
    }

    const isQuizActive = quiz?.active;

    return (
      <div className="flex w-full flex-row items-center justify-center">
        {isQuizActive ? (
          <RoundedButton
            className="hover:bg-missileaccent/90 w-full max-w-60 bg-missileaccent font-semibold text-white"
            onClick={() => {
              stopQuiz();
              setState("revealed");
            }}
          >
            {t("reveal")}
          </RoundedButton>
        ) : (
          <RoundedButton
            className="hover:bg-missileaccent/90 w-full max-w-60 bg-missileaccent font-semibold text-white"
            onClick={() => {
              startQuiz();
              setState("started");
            }}
          >
            Draw
          </RoundedButton>
        )}
      </div>
    );
  }

  if (!currentComponent) {
    return null;
  }

  return (
    <ScriptWriter
      showCharacter
      // todo: set "1000" to false when bug fixed in hanzi-writer repo
      showHintAfterMisses={context?.enableHints ? 2 : 1000}
      showOutline={context?.showShadow}
      showGridlines={context?.showGrid}
      component={currentComponent}
      onCompleteQuiz={() => setState("revealed")}
      controls={renderControlButtons}
    />
  );
}
