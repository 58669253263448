import { type ReactNode, useEffect, useRef } from "react";
import { FiX } from "react-icons/fi";
import ReactDOM from "react-dom";
import classes from "./Modal.module.scss";
import { clsx } from "clsx";

interface ModalProps {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  /** When set to true, ignores taps on the overlay that would otherwise call `onClose()` */
  sticky?: boolean;
  image?: string;
  imageText?: string;
  className?: string;
}

export default function Modal({ children, isOpen, onClose, sticky, image, imageText, className }: ModalProps) {
  const overlayRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function onKeyPress(ev: KeyboardEvent) {
      if (ev.key === "Escape" && isOpen) {
        onClose();
      }
    }
    document.addEventListener("keydown", onKeyPress);
    return () => {
      document.removeEventListener("keydown", onKeyPress);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    if (isOpen && modalRef.current) {
      modalRef.current.focus();
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div ref={modalRef} tabIndex={-1} className="fixed z-[9999]">
      <div
        ref={overlayRef}
        className={clsx(
          "fixed inset-0 z-[999] flex items-center justify-center overflow-auto bg-black/[0.25]",
          !isOpen ? "pointer-events-none" : "pointer-events-auto",
          classes.overlay,
          className,
        )}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            onClose();
          }
        }}
        onClick={
          !sticky
            ? (e) => {
                if (overlayRef.current === e.target) {
                  onClose();
                }
              }
            : undefined
        }
      >
        <ActualModal onClose={onClose} image={image} imageText={imageText}>
          {children}
        </ActualModal>
      </div>
    </div>,
    document.querySelector("body") as Element,
  );
}

function ActualModal({
  onClose,
  children,
  image,
  imageText,
}: {
  children: React.ReactNode;
  onClose: () => void;
  image?: string;
  imageText?: string;
}) {
  return (
    <div
      className="z-[999] max-h-screen min-w-[100%] p-4 sm:min-w-[50%] sm:max-w-screen-sm lg:min-w-[40%] lg:max-w-screen-md xl:min-w-[30%]"
      aria-modal
      aria-haspopup
    >
      <div className="relative overflow-hidden rounded-2xl bg-surface2 shadow-md">
        {image && (
          <div className="relative">
            {imageText ? (
              <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center bg-black/30 text-4xl font-bold text-white">
                <span>{imageText}</span>
              </div>
            ) : null}
            <div className="absolute right-5 top-5">
              <button
                type="button"
                className="flex size-10 items-center justify-center rounded-full bg-surface2 text-xl transition-all hover:bg-missilesurfacedark"
                aria-label="Close"
                onClick={onClose}
              >
                <FiX />
              </button>
            </div>

            <img src={image} alt="promo banner" height={375} className="w-full object-cover" />
          </div>
        )}

        <div className="p-4">
          {!image && (
            <div className="mb-2 flex w-full justify-end">
              <button
                type="button"
                className="flex size-10 items-center justify-center rounded-full bg-surface2 text-xl transition-all hover:bg-missilesurfacedark"
                aria-label="Close"
                onClick={onClose}
              >
                <FiX />
              </button>
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
}
