import useActiveCourse from "@rocketlanguages/shared/hooks/useActiveCourse";
import {
  RoundedButton,
  ModalFacelift,
  ModalFaceliftContent,
  ModalFaceliftOverlay,
  ModalFaceliftCloseButton,
} from "@rocketlanguages/ui";
import useActiveProduct from "@rocketlanguages/shared/hooks/useActiveProduct";
import useCountdown from "@rocketlanguages/shared/hooks/useCountdown";
import { useBannerStore } from "~/layouts/components/Banner/Banner.store";
import { shallow } from "zustand/shallow";
import { Fragment } from "react";
import RocketMarkdown from "@rocketlanguages/shared/components/Lesson/MarkdownComponent/includes/RocketMarkdown";
import { Check as CheckIcon } from "iconoir-react";
import { usePricingLink } from "~/layouts/components/TopBar/TopBar.utils";

/** Used in Padlocked Lesson Items & Padlocked Tools sidebar */
export default function BuyNowModal(props: { isOpen: boolean; onClose: () => void }) {
  const productId = useActiveProduct()?.id;
  if (!props.isOpen || !productId) {
    return null;
  }
  return <BuyNowModalView onClose={props.onClose} productId={productId} />;
}

export function BuyNowModalView(props: { onClose(): void; productId: number }) {
  const activeCourse = useActiveCourse();
  const link = usePricingLink();
  const bannerState = useBannerStore(
    (state) => ({
      modalImageUrl: state.modalImageUrl,
      expiresAtEpochSeconds: state.expiresAtEpochSeconds,
      pricingPageUrl: state.pricingPageUrl,
      subtitle: state.subtitle,
      buttonText: state.buttonText,
    }),
    shallow,
  );

  if (!activeCourse) {
    return null;
  }

  const hasPromo = !!bannerState.modalImageUrl;
  const banner = hasPromo
    ? bannerState
    : {
        modalImageUrl: `https://s3.rocketlanguages.com/images/front_end/hero-backgrounds/${activeCourse.slug}-hero-background.webp`,
        expiresAtEpochSeconds: 0,
        subtitle: "",
        pricingPageUrl: link,
        buttonText: "Buy now",
      };

  return (
    <ModalFacelift isOpen onEscapeKeyDown={props.onClose}>
      <ModalFaceliftOverlay onPointerOutside={props.onClose} />
      <ModalFaceliftContent className="bg-surface2">
        <div className="relative overflow-hidden">
          {!hasPromo ? (
            <div className="pointer-events-none absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center bg-black/40">
              <span className="text-center text-4xl font-bold text-white">{`Let's get you talking in ${activeCourse.name}`}</span>
            </div>
          ) : null}
          <img src={banner.modalImageUrl} alt="promo banner" height={360} width={640} className="w-full object-cover" />
          <ModalFaceliftCloseButton onClick={() => props.onClose?.()} className="rounded-full bg-white p-1" />
        </div>
        <div className="p-4">
          <div className="my-6 flex flex-col items-center justify-center space-y-6">
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <CheckIcon strokeWidth={2} />
                The gold standard in language courses
              </div>
              <div className="flex items-center gap-2">
                <CheckIcon strokeWidth={2} />
                High quality, in-depth courses that work
              </div>
              <div className="flex items-center gap-2">
                <CheckIcon strokeWidth={2} />
                One payment for 24/7 lifetime access
              </div>
              <div className="flex items-center gap-2">
                <CheckIcon strokeWidth={2} />
                No risk 60-day money back guarantee
              </div>
            </div>
            {banner.expiresAtEpochSeconds > 0 && (
              <div className="text-center">
                Time remaining:
                <div className="my-2 text-missileaccent">
                  <BannerCountdown endTimeEpochSeconds={banner.expiresAtEpochSeconds} />
                </div>
                {banner.subtitle ? <RocketMarkdown>{banner.subtitle}</RocketMarkdown> : null}
              </div>
            )}
          </div>
          <a href={banner.pricingPageUrl} target="_blank" rel="noreferrer noopener">
            <RoundedButton className="w-full bg-missileaccent text-lg font-semibold text-white hover:bg-missileaccent/90">
              {banner.buttonText}
            </RoundedButton>
          </a>
        </div>
      </ModalFaceliftContent>
    </ModalFacelift>
  );
}

function BannerCountdown({ endTimeEpochSeconds }: { endTimeEpochSeconds: number }) {
  const { isRunning, hoursMinutesSeconds } = useCountdown(endTimeEpochSeconds);

  const units = ["days", "hours", "mins", "secs"];
  if (!isRunning) {
    return null;
  }

  return (
    <div className="flex justify-center">
      {units.map((key, i) => (
        <Fragment key={key}>
          <div className="flex w-8">
            <div className="flex w-full flex-col text-center">
              <div className="h-6 text-2xl font-black">
                {`${hoursMinutesSeconds[key as keyof typeof hoursMinutesSeconds]}`.padStart(2, "0")}
              </div>
              <span className="text-md">{key}</span>
            </div>
          </div>
          {i < units.length - 1 && <div className="px-2 pt-0 text-2xl font-black">:</div>}
        </Fragment>
      ))}
    </div>
  );
}
